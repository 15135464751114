<template>
  <v-container>
    <v-row class="cards">
      <v-col md="6" cols="12">
        <Card :value="balanceAnticipation" title="Saldo Total Disponível" class="success-title" />
      </v-col>
      <v-col cols="12" sm="3">
        <v-snackbar
          v-model="snackbar"
          :bottom="y === 'bottom'"
          :color="color"
          :left="x === 'left'"
          :multi-line="mode === 'multi-line'"
          :right="x === 'right'"
          :timeout="timeout"
          :top="y === 'top'"
          :vertical="mode === 'vertical'"
        >
          {{ error }}
          <v-btn dark text @click="snackbar = false">X</v-btn>
        </v-snackbar>
      </v-col>
      <v-col md="12">
        <span class="success-title">Valores Disponíveis</span>
        <v-data-table
          v-if="dataTableItems[0].available !== '' && dataTableItems.length > 0"
          hide-default-footer
          show-select
          :items-per-page="dataTableItems.length"
          :mobile-breakpoint="600"
          class="dataTableContent"
          :custom-sort="service.customSort"
          v-model="selected"
          :headers="headers"
          :items="dataTableItems"
          :single-select="singleSelect"
          item-key="detailId"
        >
          <template v-slot:item.available="{ item }">
            <span :class="getAvailable()">{{ item.available }}</span>
          </template>

          <template v-slot:item.discount="{ item }">
            <span :class="getDiscount()">{{ item.discount }}</span>
          </template>
        </v-data-table>
        <v-data-table
          v-else
          hide-default-footer
          :items-per-page="dataTableItems.length"
          :mobile-breakpoint="600"
          class="dataTableContent"
          :headers="headers"
          :items="dataTableItems"
        ></v-data-table>
      </v-col>
      <v-card class="ml-4 card-received" max-width="659" outlined>
        <v-list-item>
          <v-list-item-content>
            <div class="mb-4 mt-4 ml-8 title-received">Selecione as datas acima para antecipar</div>
            <v-divider class="mx-1"></v-divider>
            <div class="third-row">
              <div class="mb-6 mt-6 ml-6 total-values">Total Solicitado</div>
              <div class="mb-6 mt-6 total-solicitation">{{this.finalAvailable}}</div>
            </div>
            <div class="third-row">
              <div class="mb-6 mt-2 ml-6 total-values">Total de descontos</div>
              <div class="mb-6 mt-2 total-discount">- {{this.finalDiscount}}</div>
            </div>
            <v-divider class="mx-1"></v-divider>
            <div class="third-row">
              <div class="mb-6 mt-6 ml-6 total-received">Total à Receber</div>
              <div class="mb-6 mt-6 ml-12 total-received">{{this.finalLiquid}}</div>
              <Detail
                :requestedAmount="this.finalLiquid"
                :preAnticipationId="this.dataTableItems[0].id"
                :listPreAnticipationDetailId="this.selected.map(el => el.detailId)"
                 @reloadGrid="reloadData"
                 @clearSelected="clearSelected"
                v-if="$vuetify.breakpoint.mdAndUp && selected.length > 0"
              />
            </div>
            <Detail
              :requestedAmount="this.finalLiquid"
              :preAnticipationId="this.dataTableItems[0].id"
              :listPreAnticipationDetailId="this.selected.map(el => el.detailId)"
              @reloadGrid="reloadData"
              @clearSelected="clearSelected"
              class="mr-6"
              v-if="$vuetify.breakpoint.smAndDown && selected.length > 0"
            />
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Card from '../../../components/Card.vue';
import service from '../../../service';
import Detail from './Detail.vue';

export default {
  components: {
    Card,
    Detail,
  },
  data: () => ({
    singleSelect: false,
    selected: [],
    service,
    color: 'rgb(250, 123, 123)',
    mode: '',
    snackbar: false,
    text: 'Erro, favor entrar em contato com o',
    timeout: 60000,
    x: null,
    y: 'top',
    discount: 0,
    available: 0,
    liquid: 0,
    finalLiquid: 'R$ 0,00',
    finalDiscount: 'R$ 0,00',
    finalAvailable: 'R$ 0,00',
    headers: [
      {
        text: 'Selecionar tudo',
        align: 'center',
        value: null,
        sortable: false,
      },
      {
        text: 'Data',
        align: 'center',
        value: 'createDate',
        sortable: false,
      },
      {
        text: 'Valor Disponível',
        align: 'center',
        value: 'available',
        sortable: false,
      },
      {
        text: 'Desconto',
        align: 'center',
        value: 'discount',
        sortable: false,
      },
      {
        text: 'Valor Líquido',
        align: 'center',
        value: 'liquid',
        sortable: false,
      },
    ],
    countSelected: 0,
  }),

  methods: {
    ...mapActions({
      setBalanceAnticipation: 'Request/setBalanceAnticipation',
      setAllAnticipations: 'Request/setAllAnticipations',
      newTransferAnticipation: 'Request/transferAnticipation',
    }),
    headerToolTips(value) {
      if (value === 'createDate') return true;
      return false;
    },
    getAvailable() {
      return 'blue--text';
    },
    getDiscount() {
      return 'red--text';
    },
    async reloadData() {
      await this.setBalanceAnticipation();
      await this.setAllAnticipations();
      this.clearSumary();
    },
    clearSelected() {
      this.selected = [];
    },
    mountSumary() {
      this.clearSumary();
      this.selected.forEach((selected) => {
        this.discount += parseFloat(
          this.service.cleanCurrency(selected.discount),
        );
        this.available += parseFloat(
          this.service.cleanCurrency(selected.available),
        );
        this.liquid += parseFloat(
          this.service.cleanCurrency(selected.liquid),
        );
        this.finalLiquid = this.service.formatCurrency(this.liquid);
        this.finalAvailable = this.service.formatCurrency(this.available);
        this.finalDiscount = this.service.formatCurrency(this.discount);
      });
    },
    clearSumary() {
      this.finalLiquid = 'R$ 0,00';
      this.finalDiscount = 'R$ 0,00';
      this.finalAvailable = 'R$ 0,00';
      this.discount = 0;
      this.available = 0;
      this.liquid = 0;
    },
  },
  computed: {
    ...mapGetters({
      getBalanceAnticipation: 'Request/getBalanceAnticipation',
      getAnticipations: 'Request/getAnticipations',
      error: 'Request/getError',
    }),
    dataTableItems() {
      return this.getAnticipations
        ? this.getAnticipations.map(el => ({
          createDate: this.$moment(el.dataSchedule).format('DD/MM/YYYY'),
          liquid: this.service.formatCurrency(el.totalAnticipationLiquid),
          discount: this.service.formatCurrency(el.totalAnticipationInterest),
          available: this.service.formatCurrency(
            el.totalAnticipationAvailable,
          ),
          detailId: el.preAnticipationDetailId,
          id: el.preAnticipationId,
        }))
        : [
          {
            createDate: '',
            liquid: '',
            discount: '',
            available: '',
          },
        ];
    },
    balanceAnticipation() {
      return this.getBalanceAnticipation
        ? this.service.formatCurrency(this.getBalanceAnticipation.balance)
        : 'R$ 0,00';
    },
  },
  watch: {
    selected(value) {
      if (value.length > this.countSelected) {
        this.countSelected = this.countSelected + 1;
        this.mountSumary();
      } else {
        this.countSelected = this.countSelected - 1;
        this.mountSumary();
      }
    },
  },
  async created() {
    await this.setBalanceAnticipation();
    await this.setAllAnticipations();
    if (this.error) this.snackbar = true;
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/supplier/anticipation.scss";
</style>
