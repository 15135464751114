<template>
  <v-row class="rowDialog">
    <v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.smAndDown"
    max-width="430" class="inputs-dialog">
      <template v-slot:activator="{ on }">
        <v-btn :loading="loadingBtn" color="#D70472" @click="openModal" v-on="on" class="btn-value mb-6 mt-6 ml-6">Antecipar</v-btn>
      </template>
      <v-card class="contentCard">
        <v-card-title class="headline">
          <v-col cols="10" class="modal-title titleAnticipationModal">
            Antecipação de Recebíveis
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="icon" cols="1">
            <v-icon  @click="closeModal" class="close-icon ">
              mdi-window-close
            </v-icon>
          </v-col>
        </v-card-title>

          <v-divider  class="mx-6 ml-6 divider divider-consult"></v-divider>

        <v-card-text class="modal-form">

          <v-row class="success-text" v-if="success === true">
            <v-col cols="12">
              <img class="imgSuccess mt-12" src="@/assets/success.png" />
              <h5 class="succesTitle">Valor antecipado<br> com sucesso</h5>
            </v-col>
          </v-row>

          <v-row class="success-text" v-else-if="error">
            <v-col cols="12">
              <img class="imgSuccess mt-12" src="@/assets/erro.png" />
              <h5 class="erroTitle">Erro ao efetuar a <br>antecipação de recebíveis</h5>
            </v-col>
          </v-row>
        </v-card-text>
        <v-row class="buttons-actions">
          <v-btn
            @click="closeModal"
            class="btnClose mb-12">
            Fechar
          </v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import service from '../../../service';

export default {
  props: {
    requestedAmount: String,
    preAnticipationId: Number,
    listPreAnticipationDetailId: Array,
  },
  data() {
    return {
      btnLoading: false,
      showModal: true,
      dialog: false,
      service,
      loadingBtn: false,
    };
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.closeModal();
      }
    },
  },
  computed: {
    ...mapGetters({
      success: 'Request/getSuccessAnticipation',
      error: 'Request/getError',
    }),
  },
  methods: {
    ...mapMutations({
      clearTransfer: 'Request/SET_SUCCESS_ANTICIPATION',
      clearError: 'Request/CLEAR_ERROR',
    }),
    ...mapActions({
      setNewTransfer: 'Request/transferAnticipation',
    }),
    async closeModal() {
      await this.clearError();
      await this.clearTransfer(null);
      this.$emit('clearSelected');
      this.dialog = false;
      this.requestSuccess = false;
      this.showModal = false;
    },
    async openModal() {
      this.loadingBtn = true;
      await this.newTransfer();
      if (this.success === true || this.error) this.dialog = true;
      this.loadingBtn = false;
      this.$emit('reloadGrid', true);
    },
    newTransfer() {
      const payload = {
        requestedAmount: parseFloat(this.service.cleanCurrency(this.requestedAmount), 10),
        preAnticipationId: this.preAnticipationId,
        listPreAnticipationDetailId: this.listPreAnticipationDetailId.join(),
      };
      this.setNewTransfer(payload);
    },
  },
  destroyed() {
    this.clearTransfer(null);
    this.clearError();
  },
};
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/supplier/detail.scss';
</style>
